import API, { APIV2, APIV3 } from "../_config";

interface FilterProps {
  min_price?: number;
  max_price?: number;
  task_type?: string;
  distance?: number;
  current_lat?: string;
  current_lng?: string;
  sort_by?: string;
  sort_direction?: string;
  "categories[]"?: string[];
  category?: any;
  search?: string;
}

interface FetchTasksProps {
  search_query?: string;
  filter?: FilterProps;
  page?: number;
  limit?: string;
  category?: any;
}

interface dismissNotificationProps {
  id: string;
}

export function fetchJobs({
  search_query,
  filter,
  page,
  limit,
  category,
}: FetchTasksProps) {
  const urlQuery = new URLSearchParams();
  if (filter) {
    if (filter.search) urlQuery.append("filter[search]", filter.search);
    if (filter.sort_by) {
      if (filter.sort_by === "budget") {
        if (filter.sort_direction === "Descending") {
          urlQuery.append("sort", `-${filter.sort_by.split(" ").join("_")}`);
        } else {
          urlQuery.append(
            "sort",
            `${filter.sort_by.toLowerCase().split(" ").join("_")}`
          );
        }
      }
      if (filter.sort_by === "Due date") {
        if (filter.sort_direction === "Descending") {
          urlQuery.append(
            "sort",
            `-${filter.sort_by.toLocaleLowerCase().split(" ").join("_")}`
          );
        } else {
          urlQuery.append(
            "sort",
            `${filter.sort_by.toLocaleLowerCase().split(" ").join("_")}`
          );
        }
      }
    }
    if (filter.sort_by === "most recent") {
      urlQuery.delete("sort");
    }
    if (filter.max_price) {
      urlQuery.append(
        "filter[price_range]",
        `${filter.min_price},${filter.max_price}`
      );
    }
    if (filter.task_type) {
      urlQuery.append("filter[type]", filter.task_type.toLocaleLowerCase());
    }
    if (filter.category) {
      const categoriesTitle: any = [];
      filter.category.map((item: any) => {
        categoriesTitle.push(item.title);
      });
      urlQuery.append("filter[category]", categoriesTitle);
    }
    if (
      (filter.task_type === "physical" || filter.task_type === "Both") &&
      filter.current_lat &&
      filter.current_lng &&
      filter.distance
    ) {
      urlQuery.append(
        "filter[location]",
        `${filter.current_lat},${filter.current_lng},${
          filter.distance === 100 ? 100 : filter.distance
        }`
      );
    }
  }
  if (page) urlQuery.append("page", String(page));
  return APIV3.get(
    `jobs/filter?${urlQuery.toString()}&status=open${
      limit ? `&record_limit=${limit}` : ""
    }`
  )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function fetchMapJob({ lat, lng }: any) {
  return APIV3.get(
    `jobs/filter?page=1&record_limit=100&filter[location]=${lat},${lng},4000&type=physical`
  )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function fetchJob(slug: string) {
  return APIV3.get(`tasks/${slug}`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function submitOffer(data: any) {
  return API.post(`offers`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function deleteOffer(offerId: any) {
  return API.delete(`offers/${offerId}`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function fetchBillingAddress() {
  return API.get(`account/billingaddress`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function addBillingAddress(data: any) {
  return API.post(`account/billingaddress`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function saveQuickOffer(data: any) {
  return API.post(`quick_offers`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function updateQuickOffer({ id, data }: any) {
  return API.post(`quick_offers/${id}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function getAllQuickOffers() {
  return API.get(`quick_offers`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function deleteQuickOffer(id: number) {
  return API.delete(`quick_offers/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function fetchAccount() {
  return API.get(`account`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function fetchNotifications(page: any) {
  return API.get(`/notifications`, {
    params: {
      page,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function fetchUnreadNotificationsCount() {
  return API.get(`/notifications/unread-count`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function fetchBankAccount() {
  return API.get(`account/bankaccount`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function deleteBankAccount(id: number) {
  return API.delete(`account/bankaccount/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function updateBillingAddress(data: any) {
  return API.post(`account/billingaddress`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function fetchEarningOfferCalculation(amount: number | undefined) {
  return API.post(`offers/earning_calculation`, {
    amount,
  })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function fetchPayingOfferCalculation(amount: number | undefined) {
  return API.post(`offers/paying_calculation`, {
    amount,
  })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function uploadUserAvatar(file: any) {
  return API.post(`profile/upload-avatar`, file, {
    headers: {
      accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": `multipart/form-data; boundary=${file._boundary}`,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function dismissNotification({ id }: dismissNotificationProps) {
  return API.post(`notifications/mark-read`, { id })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function dismissAllNotification() {
  return API.post(`notifications/mark-all-read`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function taskAlertList() {
  return API.get(`taskalerts`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function createJobAlert(data: any) {
  if (data.task_type === "remote") {
    delete data.latitude;
    delete data.longitude;
    delete data.location;
    delete data.neighborhood;
  }
  return APIV3.post(`task-alert`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
export function updateJobAlert(data: any, id?: any) {
  return APIV3.patch(`task-alert/${id}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
export function fetchJobAlert() {
  return APIV3.get(`task-alert`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
export function fetchSingleJobAlert(id: number) {
  return APIV3.get(`task-alert/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
export function DeleteJobAlert(id: any) {
  return APIV3.delete(`task-alert/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
