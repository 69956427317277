import API, { APIV2 } from "./_config";

export function fetchProfileProgress() {
  return API.get(`/profile-progress`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function requestSendPhoneOtp(data) {
  return API.post(`/account/phone_send_otp`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
export function editProfileInfo(data) {
  return API.post(`profile/info`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
export function verifyPhone(data) {
  return API.post(`/account/phone_verification`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
export function fetchAccount() {
  return API.get(`account`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
export function changePassword(data) {
  return API.post(`account/change-password`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
export function fetchProfile(slug) {
  return API.get(`profile/slug/${slug}`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
export function editProfile(data) {
  return API.post(`profile/info`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
export function fetchUserReviews(userId, role, page) {
  return API.get(`/profile/${userId}/reviews/${role}`, {
    params: { page },
  })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function uploadUserPortfolio(file) {
  let formData = new FormData();
  formData.append("media", file, file.fileName);
  return API.post(`profile/portfolio`, formData, {
    headers: {
      accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
export function deleteUserPortfolio(portfolioId) {
  return API.delete(`profile/portfolio/${portfolioId}`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function uploadUserAvatar(file) {
  let formData = new FormData();
  formData.append("media", file, file.fileName);
  return API.post(`profile/upload-avatar`, formData, {
    headers: {
      accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function removeUserAvatar() {
  return API.delete(`profile/avatar`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export function updateSkills(data) {
  return API.post(`profile/skills`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
export function getAllSkills() {
  return API.get(`skills`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
export function phoneGenerateOtp(data) {
  return API.post(`account/phone_send_otp`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
export function phoneVerification(data) {
  return API.post(`account/phone_verification`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}
